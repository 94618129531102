.main::before {
  content: "";
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../../../public/bg.png');
}
.card::after {
  content: "";
  background: #01eeff;
  bottom: -5px;
  right: -5px;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}